import React, { useEffect, useState } from "react";
import { isWebpSupported } from "../../utils/isWebpSupported";

interface ImageProps {
  src: string;
  webpSrc?: string;
  alt?: string;
  className?: string;
}

export function Image(props: ImageProps) {
  const { src, webpSrc, alt = "", className } = props;
  const [renderWebp, setRenderWebp] = useState(true);

  useEffect(() => {
    if (webpSrc) {
      isWebpSupported("lossy", (feature, isSupported) => {
        if (!isSupported) {
          setRenderWebp(false);
        }
      });
    }
  }, [webpSrc]);

  return (
    <img
      src={webpSrc && renderWebp ? webpSrc : src}
      alt={alt}
      className={className}
    />
  );
}
