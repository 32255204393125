import classNames from "classnames";
import React, { useContext, useState } from "react";
import { useCookies } from "react-cookie";
import { Button } from "../Button";
import { CalculatorDropdown } from "../CalculatorDropdown";
import { Logo } from "../Logo";
import { MobileMenu } from "../MobileMenu";
import { PracticeDropdown } from "../PracticeDropdown";
import { AuthUserContext } from "../Session";
import { SheetsDropdown } from "../SheetsDropdown";
import style from "./Header.module.scss";

interface HeaderProps {
  children?: any;
  transparent: boolean;
}

export function Header(props: HeaderProps) {
  const { transparent } = props;
  const [shownDropdown, setShownDropdown] = useState(null);
  const [cookies] = useCookies(["chemistryshark_auth"]);
  const authUser = useContext(AuthUserContext);
  const hasSubscription = authUser && authUser.hasSubscription;

  return (
    <div className={classNames(style.header, transparent && style.transparent)}>
      <div className={style.content}>
        <div className={style.logo}>
          <Logo link />
        </div>
        <div className={style.products}>
          <CalculatorDropdown
            shownDropdown={shownDropdown}
            setShownDropdown={setShownDropdown}
          />
          <SheetsDropdown
            shownDropdown={shownDropdown}
            setShownDropdown={setShownDropdown}
          />
          <PracticeDropdown
            shownDropdown={shownDropdown}
            setShownDropdown={setShownDropdown}
          />
          {(!authUser || !hasSubscription) &&
            cookies.chemistryshark_auth !== "2" && (
              <div onMouseOver={() => setShownDropdown(null)}>
                <Button href="/pricing" kind="none">
                  Pricing
                </Button>
              </div>
            )}
        </div>
        <div className={style.actions}>
          {authUser || !!cookies.chemistryshark_auth ? (
            <Button href="/account" kind="none">
              Account
            </Button>
          ) : (
            <Button href="/login" kind="none">
              Sign In
            </Button>
          )}
        </div>

        <div className={style.mobileMenu}>
          <MobileMenu />
        </div>
      </div>
    </div>
  );
}
