import React from "react";
import { withCookies } from "react-cookie";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import { Hero } from "../Hero";
import { LoadingIcon } from "../LoadingIcon";
import { Page } from "../Page";
import { AuthUserContext } from "./context";

export const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component<{
    firebase?: any;
    history?: any;
    cookies?: any;
  }> {
    listener: any;
    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
        if (!condition(authUser)) {
          this.props.history.push("/login");
        } else {
          // not in push
        }
      });
    }
    componentWillUnmount() {
      this.listener();
    }
    render() {
      // const cookieAuth = cookies.get("chemistryshark_auth");

      return (
        <AuthUserContext.Consumer>
          {authUser => {
            return condition(authUser) ? (
              // || condition()
              <Component {...this.props} />
            ) : (
              <Page transparent>
                <Hero kind="primary" />
                <LoadingIcon />
                <div style={{ color: "#fff" }}>Loading your experience...</div>
              </Page>
            );
          }}
        </AuthUserContext.Consumer>
      );
    }
  }
  return compose(withRouter, withFirebase, withCookies)(WithAuthorization);
};
