import React from "react";
import style from "./Heading.module.scss";
import classNames from "classnames/bind";

interface HeadingProps {
  children?: any;
  kind?: "h1" | "h2" | "h3" | "h3" | "h4" | "h5" | "h6";
  weight?: "bold" | "normal";

  className?: any;
}

export function Heading(props: HeadingProps) {
  const { kind = "h4", weight = "bold", className, children } = props;
  const Label = kind;
  return (
    <Label className={classNames(style.heading, style[kind], style[weight], className)}>
      {children}
    </Label>
  );
}
