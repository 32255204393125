import React from "react";
import { Icon } from "../../components/Icon";
import { MenuDropdown } from "../MenuDropdown";
import { MenuItem } from "../MenuItem";
import iconVoltaic from "./assets/battery.svg";
import iconMass from "./assets/molecule.svg";
import iconPh from "./assets/ph-meter.svg";
import iconLab from "./assets/pour.svg";
import iconQuad from "./assets/science-book.svg";
import iconTemp from "./assets/thermometer.svg";
import style from "./CalculatorDropdown.module.scss";

export const mainCalculatorLinks = [
  {
    title: "pH Calculator",
    url: "/calculator/ph",
    description:
      "Calculate pH, concentrations, and volumes of acid base solutions, showing step-by-step solutions and graphs.",
    icon: iconPh
  },
  {
    title: "Titration Lab",
    url: "/calculator/titration-lab",
    description:
      "Try to reach equivalence with our titration lab! Adjust burette volume, titration speed, indicator, and more.",
    icon: iconLab
  },
  {
    title: "Voltaic Cell Calculator",
    url: "/calculator/voltaic",
    description:
      "Calculate the standard cell potential, half-reactions, and spontaneity of common electrodes and electrolyes.",
    icon: iconVoltaic
  }
];

export const subCalculatorLinks = [
  {
    title: "Molar Mass Calculator",
    url: "/calculator/mass",
    description:
      "Enter the formula of an element or compound to calculate its molecular weight.",
    size: "small",
    icon: iconMass
  },
  {
    title: "Quadratic Formula Calculator",
    url: "/calculator/quadratic",
    description:
      "Save the headache of solving quadratic formula equation roots by hand.",
    size: "small",
    icon: iconQuad
  },
  {
    title: "Temperature Converter",
    url: "/calculator/temperature",
    description:
      "Convert between Fahrenheit, Celsius, and Kelvin with this handy tool.",
    size: "small",
    icon: iconTemp
  }
];

export function CalculatorDropdown(props: any) {
  return (
    <MenuDropdown
      trigger="Calculators"
      slug="calculators"
      menuStyle={style.menu}
      contentStyle={style.content}
      {...props}
    >
      <div className={style.main}>
        {mainCalculatorLinks.map(link => (
          <MenuItem
            title={link.title}
            url={link.url}
            description={link.description}
            icon={<Icon icon={link.icon} />}
            key={link.title}
          />
        ))}
      </div>

      <div className={style.sub}>
        {subCalculatorLinks.map(link => (
          <MenuItem
            title={link.title}
            url={link.url}
            key={link.title}
            description={link.description}
            icon={<Icon icon={link.icon} size="small" />}
          />
        ))}
      </div>
    </MenuDropdown>
  );
}
