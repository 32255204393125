import classNames from "classnames";
import React from "react";
import style from "./Button.module.scss";

interface ButtonProps {
  children?: any;
  kind?: "light" | "dark" | "select" | "primary" | "cancel" | "none";
  outline?: boolean;
  href?: string;
  onClick?: any;
  block?: boolean;

  type?: "submit" | "reset" | "button";

  buttonStyle?: any;
  iconAfter?: any;
  disabled?: boolean;
  isselected?: boolean;
}

export function Button(props: ButtonProps) {
  const {
    children,
    href,
    kind = "light",
    outline = false,
    onClick,
    iconAfter,
    block,
    disabled,
    type = "button",
    buttonStyle,
    ...rest
  } = props;
  const Tag = href ? "a" : "button";
  return (
    <Tag
      href={href ? href : undefined}
      onClick={onClick}
      type={type}
      disabled={disabled}
      className={classNames(
        buttonStyle,
        style.button,
        outline && style.outline,
        iconAfter && style.withIcon,
        style[kind],
        block && style.block
      )}
      {...rest}
    >
      {children}
      {iconAfter && <span className={style.iconAfter}>{iconAfter}</span>}
    </Tag>
  );
}
