import React, { Component as ReactComponent } from "react";
import { withCookies } from "react-cookie";
import { db, withFirebase } from "../Firebase";
import { AuthUserContext } from "./context";

export const withAuthentication = Component => {
  class WithAuthentication extends ReactComponent<{
    firebase?: any;
    cookies?: any;
  }> {
    state = {
      authUser: null
    };
    listener: any;

    componentDidMount() {
      const { cookies, firebase } = this.props;
      this.listener = firebase.auth.onAuthStateChanged(authUser => {
        if (authUser) {
          db.collection("users")
            .doc(authUser.email)
            .get()
            .then(user => {
              if (user.exists) {
                const userData = user.data();
                if (
                  userData &&
                  userData.plan &&
                  userData.plan.current_subscription_end &&
                  userData.plan.current_subscription_end > Date.now() / 1000 &&
                  cookies.get("chemistryshark_auth") !== "2"
                ) {
                  cookies.set("chemistryshark_auth", 2, { path: "/" });
                }
                this.setState({
                  authUser: {
                    ...authUser,
                    ...userData
                  }
                });
              } else {
                // user doesn't exist
              }
            })
            .catch(e => {
              // err
              this.setState({
                authUser
              });
            });
          if (!cookies.get("chemistryshark_auth")) {
            cookies.set("chemistryshark_auth", 1, { path: "/" });
          }
        } else {
          cookies.remove("chemistryshark_auth", { path: "/" });
        }
        // authUser
        //   ? this.setState({ authUser })
        //   : this.setState({ authUser: null });
      });
    }
    componentWillUnmount() {
      this.listener();
    }
    render() {
      const { authUser } = this.state;
      let userData = authUser;

      if (authUser) {
        let plan = null;
        if (authUser.plan && authUser.plan) {
          const {
            active,
            amount,
            cancel_at_period_end,
            currency,
            created,
            subscription_id,
            current_subscription_end,
            current_subscription_start,
            nickname
          } = authUser.plan;
          plan = {
            active,
            amount,
            cancel_at_period_end,
            currency,
            created,
            subscription_id,
            current_subscription_start,
            current_subscription_end,
            nickname
          };
        }
        userData = {
          email: authUser.email,
          hasSubscription:
            authUser.plan &&
            authUser.plan.current_subscription_end &&
            authUser.plan.current_subscription_end > Date.now() / 1000,
          plan
        };
      }

      return (
        <AuthUserContext.Provider value={userData}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }
  return withFirebase(withCookies(WithAuthentication));
};
