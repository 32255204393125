import classNames from "classnames";
import React from "react";
import { Helmet } from "react-helmet-async";
import { Footer } from "../Footer";
import { Header } from "../Header";
import style from "./Page.module.scss";

interface PageProps {
  children?: any;
  breakout?: boolean; // break out of page width constrains

  kind?: "dark" | "light" | "primary";
  transparent?: boolean;

  header?: boolean;
  footer?: boolean;

  title?: string;
  description?: string;
  keywords?: string;
}

export function Page(props: PageProps) {
  const {
    children,
    breakout,
    kind = "light",
    transparent,
    header = true,
    footer = true,
    title = "Chemistryshark: Chemistry Calculators, Cheat Sheets, and Practice Tools",
    description = "Chemistryshark offers students chemistry resources such as unique calculators, formula sheets, practice tools, and more.",
    keywords = "Chemistry,Calculators,pH Calculator,Titration Calculator,Formula Sheets,Voltaic Cell,Molar Mass, Periodic Table, Practice Tools",
  } = props;

  const hasStripeLoaded =
    // @ts-ignore
    typeof window.Stripe === "function";

  return (
    <div className={classNames(style.page, style[kind])}>
      <Helmet>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <title>{title ? `${title} | ` : ""}Chemistryshark</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        {!hasStripeLoaded && <script src="https://js.stripe.com/v3/"></script>}
      </Helmet>

      {header && <Header transparent={transparent || kind === "dark"} />}
      <div className={classNames(style.content, breakout && style.breakout)}>
        {children}
      </div>
      {footer && <Footer kind={kind} />}
    </div>
  );
}
