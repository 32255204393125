import classNames from "classnames";
import React from "react";
import style from "./Icon.module.scss";

interface IconProps {
  size?: "xs" | "small" | "full" | "normal";
  icon: any;
  alt?: string;
}

export function Icon(props: IconProps) {
  const { size = "normal", alt = "" } = props;
  return (
    <div className={classNames(style.svg, style[size])}>
      <img src={props.icon} alt={alt} />
    </div>
  );
}
