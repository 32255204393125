import React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useTracking } from "../../utils/useTracking";
import { Anchor } from "../Anchor";
import { Button } from "../Button";
import { Heading } from "../Heading";
import { ProFeature } from "../ProFeature";
import style from "./HeroText.module.scss";

interface HeroTextProps {
  title: string;
  description: string;
  primaryUrl?: string;
  primaryText?: string;
  secondaryUrl?: string;
  secondaryText?: string;
  download?: boolean;
  customActions?: any;
  isPro?: boolean;
  hasSubscription?: boolean;
}

export function HeroText(props: HeroTextProps) {
  const {
    title,
    description,
    primaryUrl,
    primaryText,
    secondaryUrl,
    secondaryText,
    download,
    customActions,
    isPro,
    hasSubscription
  } = props;

  const location = useLocation();
  const { createEvent } = useTracking();

  let supertitle = null;

  if (location.pathname.indexOf("/memory/") > -1) {
    supertitle = {
      label: "memory games",
      href: "/memory"
    };
  } else if (location.pathname.indexOf("/sheets/") > -1) {
    supertitle = {
      label: "cheat sheets",
      href: "/sheets"
    };
  } else if (location.pathname.indexOf("/calculator/") > -1) {
    supertitle = {
      label: "calculators",
      href: "/calculator"
    };
  }

  return (
    <div className={style.heroTextWrapper}>
      {supertitle && (
        <Anchor href={supertitle.href} className={style.supertitle}>
          {supertitle.label}
        </Anchor>
      )}
      <Heading kind="h1" weight="normal">
        {title} {isPro && !hasSubscription && <ProFeature />}
      </Heading>
      <Heading kind="h2" weight="normal" className={style.description}>
        {description}
      </Heading>
      {customActions}
      {!customActions && primaryText && (
        <div className={style.actions}>
          <Button
            href={!download && primaryUrl}
            kind="dark"
            onClick={() => {
              if (createEvent) {
                createEvent({
                  category: "Hero Primary Button Click",
                  action: `${primaryText} - ${location.pathname}`
                });
              }
            }}
          >
            {download ? (
              <Link to={primaryUrl} target="_blank" download>
                {primaryText}
              </Link>
            ) : (
              primaryText
            )}
          </Button>
          {secondaryText && (
            <Button
              href={secondaryUrl}
              onClick={() => {
                if (createEvent) {
                  createEvent({
                    category: "Hero Secondary Button Click",
                    action: `${primaryText} - ${location.pathname}`
                  });
                }
              }}
            >
              {secondaryText}
            </Button>
          )}
        </div>
      )}
    </div>
  );
}
