import React from "react";
import { Anchor } from "../Anchor";
import { Card } from "../Card";
import { Heading } from "../Heading";
import { Paragraph } from "../Paragraph";
import style from "./InfoCard.module.scss";

interface InfoCardProps {
  children?: any;

  href?: string;
  icon?: any;
  title: string;
  description: string;
}

export function InfoCard(props: InfoCardProps) {
  const { icon, title, description, href = "#" } = props;
  return (
    <Anchor href={href}>
      <Card className={style.infoCard}>
        <div className={style.icon}>{icon}</div>
        <div className={style.content}>
          <div className={style.heading}>
            <Heading kind="h4">{title}</Heading>
          </div>
          <Paragraph>{description}</Paragraph>
        </div>
      </Card>
    </Anchor>
  );
}
