import classNames from "classnames";
import CalculatorIcon from "mdi-react/CalculatorIcon";
import CardTextIcon from "mdi-react/CardTextIcon";
import ToolsIcon from "mdi-react/ToolsIcon";
import React, { useContext } from "react";
import { useCookies } from "react-cookie";
import { Anchor } from "../../components/Anchor";
import phIcon from "../../components/CalculatorDropdown/assets/ph-meter.svg";
import { CTA } from "../../components/CTA";
import { Heading } from "../../components/Heading";
import { Hero } from "../../components/Hero";
import { HeroAtom } from "../../components/HeroAtom";
import { HeroText } from "../../components/HeroText";
import { Icon } from "../../components/Icon";
import { Image } from "../../components/Image";
import { InfoCard } from "../../components/InfoCard";
import { InfoSection } from "../../components/InfoSection";
import { Logo } from "../../components/Logo";
import { Page } from "../../components/Page";
import { Paragraph } from "../../components/Paragraph";
import periodicIcon from "../../components/PracticeDropdown/assets/periodic-table.svg";
import { Section } from "../../components/Section";
import { AuthUserContext } from "../../components/Session";
import { sheets } from "../../components/SheetsDropdown";
import gasesIcon from "../../components/SheetsDropdown/assets/oxygen.svg";
import sheet from "./assets/geometry_formulas.png";
import sheetWebp from "./assets/geometry_formulas.webp";
import ph_results from "./assets/pH_results.png";
import ph_resultsWebp from "./assets/pH_results.webp";
import table from "./assets/table.png";
import tableWebp from "./assets/table.webp";
import work from "./assets/work.png";
import workWebp from "./assets/work.webp";
import style from "./home.module.scss";

export default function Home() {
  const viewer = useContext(AuthUserContext);
  const hasMembership = viewer && viewer.hasSubscription;
  const [cookies] = useCookies(["chemistryshark_auth"]);
  const showMemberOption =
    (viewer && viewer.hasSubscription) || cookies.chemistryshark_auth === "2";

  return (
    <Page>
      <Hero />

      <div className={style.hero}>
        <div className={style.heroText}>
          <HeroText
            title="Take the bite of out chemistry"
            description="Explore Chemistryshark's growing list of calculators, formula sheets, and other chemistry resources."
            primaryText={
              showMemberOption ? "Explore our calculators" : "Become a pro"
            }
            primaryUrl={
              viewer && viewer.hasSubscription ? "/calculator" : "/pricing"
            }
          />
        </div>

        <div className={style.heroAtom}>
          <HeroAtom />
        </div>
      </div>

      <InfoSection
        title="Chemistry calculators"
        mdiIcon={<CalculatorIcon />}
        left={
          <div className={style.infoSide}>
            <div className={style.content}>
              <Heading kind="h3">
                A variety of tools to simplify your life
              </Heading>
              <Paragraph>
                Our pH calculator is a unique multi-functional tool for
                determining information of an acid-base solution. Whether you
                need to find the volume of acid needed to reach a certain pH,
                calculate pH of a solution, measure equilibrium values, or get
                suitable indicators for the solution, we’ve got you covered,
                along with step-by-step solutions and graphs.
              </Paragraph>
              <Paragraph>
                Have limited classroom resources? The titration lab provides an
                easy and interactive way to perform acid-base titrations,
                allowing control over which chemicals are used, titration speed,
                and much more.
              </Paragraph>
              <Paragraph>
                Also included are calculators to find the potential and
                half-reactions in a voltaic cell, molecular weight of a
                compound, get the roots of a quadratic equation, and convert
                between Fahrenheit, Celsius, and Kelvin.
              </Paragraph>
            </div>
            <InfoCard
              title="pH calculator"
              href="/calculator/ph"
              icon={<Icon icon={phIcon} size="full" />}
              description="Measure the pH of a solution during acid base chemistry, or calculate volume and concentration values, with graphs and step-by-step solutions."
            />
          </div>
        }
        right={
          <div className={style.media}>
            <Image
              src={work}
              webpSrc={workWebp}
              className={style.image}
              alt="Step-by-step solutions using Chemistryshark's pH calculator"
            />
            <Image
              src={ph_results}
              webpSrc={ph_resultsWebp}
              className={classNames(style.image, style.image_far)}
              alt="PH calculator results showing pH and volume, the acid-base curve, and preferred indicator"
            />
          </div>
        }
      />

      <Section kind="faint">
        <InfoSection
          title="Cheat sheets"
          mdiIcon={<CardTextIcon />}
          right={
            <div className={style.infoSide}>
              <div className={style.content}>
                <Heading kind="h3">
                  It's not cheating...just being resourceful.
                </Heading>
                <Paragraph>
                  The vast amount of formulas in chemistry can seem overwhelming
                  at times, and memorizing electron domain geometry or Van der
                  Waal’s Equation may not be everyone’s biggest priority. We
                  understand this, offering a selection of useful cheat sheets
                  and formula lists to help out. Check out our current list of
                  cheat sheets below:
                </Paragraph>
                <div style={{ minHeight: 200 }}>
                  <ul>
                    {sheets.map(sheet => (
                      <li className={style.sheetLink} key={sheet.title}>
                        <Anchor href={`/sheets/${sheet.slug}`}>
                          {sheet.title}
                        </Anchor>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <InfoCard
                title="Gas formulas"
                href="/sheets/gases"
                icon={<Icon icon={gasesIcon} size="full" />}
                description="Formulas and equations of common gas relationships, such as temperature, volume, pressure, and more."
              />
            </div>
          }
          left={
            <div className={classNames(style.media, style.media_left)}>
              <Image
                src={sheet}
                webpSrc={sheetWebp}
                className={style.image}
                alt="Molecular geometry cheat sheet showing electron domain geometry, bonding domains, angles, and more."
              />
            </div>
          }
        />
      </Section>

      {!hasMembership && (
        <CTA
          href="/pricing"
          button="See pricing"
          description="Become a member to receive access to all features on Chemistryshark."
          image={<Logo withText={false} />}
        />
      )}

      <InfoSection
        title="Practice tools"
        mdiIcon={<ToolsIcon />}
        left={
          <div className={style.infoSide}>
            <div className={style.content}>
              <Heading kind="h3">Perfect practice makes perfect</Heading>
              <Paragraph>
                Sometimes, all you need to figure out a problem is to have a
                quick glance at a periodic table. If that doesn’t work, use any
                one of the resources found on Chemistryshark to help out.
              </Paragraph>
              <Paragraph>
                Need to study chemistry vocabulary but dread the repetitiveness
                of flash cards? Take a look at our chemistry focused memory
                game, including topics such as thermodynamics and acids and
                bases!
              </Paragraph>
            </div>
            <InfoCard
              title="Periodic table of elements"
              href="/periodic-table"
              icon={<Icon icon={periodicIcon} size="full" />}
              description="Dynamics periodic table showing both core information such as elemental weight and atomic number, to more specific data including atomic radius and electronic configuration."
            />
          </div>
        }
        right={
          <div className={style.media}>
            <Image
              src={table}
              webpSrc={tableWebp}
              className={style.image}
              alt="Periodic table of elements"
            />
          </div>
        }
      />
    </Page>
  );
}
