import React from "react";
import style from "./MenuDropdown.module.scss";
import { Card } from "../Card";
import { Button } from "../Button";

interface MenuDropdownProps {
  children?: any;
  slug: "calculators" | "sheets" | "practice";
  trigger: string;
  shownDropdown?: "calculators" | "sheets" | "practice" | null;
  setShownDropdown?: any;

  menuStyle?: any;
  contentStyle?: any;
}

export function MenuDropdown(props: MenuDropdownProps) {
  const {
    children,
    shownDropdown,
    setShownDropdown,
    slug,
    trigger,
    menuStyle,
    contentStyle
  } = props;

  const show = () => {
    setShownDropdown(slug);
  };

  const hide = () => {
    setShownDropdown(false);
  };

  return (
    <div className={style.dropdown}>
      <Button
        kind="none"
        aria-haspopup={true}
        aria-expanded={shownDropdown === slug}
      >
        {trigger}
      </Button>
      <div className={style.background} onMouseOver={show} />
      {shownDropdown === slug && (
        <div className={menuStyle}>
          <Card arrow>
            <div
              className={contentStyle}
              onMouseEnter={show}
              onMouseLeave={hide}
            >
              {children}
            </div>
          </Card>
        </div>
      )}
    </div>
  );
}
