import React from "react";
import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import { HelmetProvider } from "react-helmet-async";
import App from "./App";
import { Firebase, FirebaseContext } from "./components/Firebase";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

if (process.env.REACT_APP_MODE !== "development") {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
}

ReactDOM.render(
  <HelmetProvider>
    <FirebaseContext.Provider value={new Firebase()}>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </FirebaseContext.Provider>
  </HelmetProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
