import React from "react";
import { useCookies } from "react-cookie";
import { Button } from "../Button";
import { withFirebase } from "../Firebase";
import { MenuItem } from "../MenuItem";

const SignOutButtonBase = ({ firebase, type }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies([
    "chemistryshark_auth"
  ]);

  if (type === "mobile") {
    return (
      <div
        onClick={() => {
          removeCookie("chemistryshark_auth", { path: "/" });
          firebase.doSignOut();
        }}
      >
        <MenuItem title="Sign out" size="small" hideIcon />
      </div>
    );
  }
  return (
    <Button
      onClick={() => {
        removeCookie("chemistryshark_auth", { path: "/" });
        firebase.doSignOut();
      }}
      kind="none"
    >
      Sign Out
    </Button>
  );
};

export const SignOutButton = withFirebase(SignOutButtonBase);
