import React, { useContext } from "react";
import { slide as Menu } from "react-burger-menu";
import { useCookies } from "react-cookie";
import iconVoltaic from "../CalculatorDropdown/assets/battery.svg";
import iconMass from "../CalculatorDropdown/assets/molecule.svg";
import iconPh from "../CalculatorDropdown/assets/ph-meter.svg";
import iconLab from "../CalculatorDropdown/assets/pour.svg";
import iconQuad from "../CalculatorDropdown/assets/science-book.svg";
import iconTemp from "../CalculatorDropdown/assets/thermometer.svg";
import { Icon } from "../Icon";
import { MenuItem } from "../MenuItem";
// import iconBook from "../PracticeDropdown/assets/book.svg";
import iconBrain from "../PracticeDropdown/assets/brain.svg";
import iconPeriodic from "../PracticeDropdown/assets/periodic-table.svg";
import { AuthUserContext } from "../Session";
import iconNotes from "../SheetsDropdown/assets/notes.svg";
import { SignOutButton } from "../SignOutButton";
import style from "./MobileMenu.module.scss";

interface MobileMenuProps {
  //   visible?: boolean;
}

const styles = {
  bmBurgerButton: {
    position: "absolute",
    width: "24px",
    height: "20px",
    right: "30px",
    top: "30px"
  },
  bmBurgerBars: {
    background: "#fff"
  },
  bmBurgerBarsHover: {
    background: "#a90000"
  },
  bmCrossButton: {
    height: "24px",
    width: "24px"
  },
  bmCross: {
    background: "#bdc3c7"
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
    top: 0
  },
  bmMenu: {
    background: "rgba(255,255,255,.97)",
    fontSize: "1.15em"
  },
  bmMorphShape: {
    fill: "#373a47"
  },
  bmItemList: {
    color: "#b8b7ad",
    padding: "0.8em"
  },
  bmItem: {
    display: "flex",
    outline: "none",
    height: "100%"
  },
  bmOverlay: {
    left: 0,
    top: 0,
    background: "rgba(0, 0, 0, 0.6)"
  }
};

export function MobileMenu(props: MobileMenuProps) {
  const [cookies] = useCookies(["chemistryshark_auth"]);
  const authUser = useContext(AuthUserContext);

  return (
    <Menu right styles={styles}>
      <div className={style.content}>
        <div className={style.list}>
          <div className={style.main}>
            <MenuItem
              title="pH Calculator"
              url="/calculator/ph"
              noMargin
              size="small"
              icon={<Icon icon={iconPh} size="xs" />}
            />
            <MenuItem
              title="Titration Lab"
              url="/calculator/titration-lab"
              noMargin
              size="small"
              icon={<Icon icon={iconLab} size="xs" />}
            />
            <MenuItem
              title="Voltaic Cell Calculator"
              url="/calculator/voltaic"
              noMargin
              size="small"
              icon={<Icon icon={iconVoltaic} size="xs" />}
            />

            <MenuItem
              title="Mass Calculator"
              url="/calculator/mass"
              size="small"
              noMargin
              icon={<Icon icon={iconMass} size="xs" />}
            />
            <MenuItem
              title="Quadratic Calculator"
              url="/calculator/quadratic"
              size="small"
              noMargin
              icon={<Icon icon={iconQuad} size="xs" />}
            />
            <MenuItem
              title="Temperature Converter"
              url="/calculator/temperature"
              size="small"
              noMargin
              icon={<Icon icon={iconTemp} size="xs" />}
            />
          </div>
          <MenuItem
            title="Cheat Sheets"
            url="/sheets"
            noMargin
            size="small"
            icon={<Icon icon={iconNotes} size="xs" />}
          />
          {/* <MenuItem
                  title="Example Problems"
                  url="/calculator/ph/examples"
                  size="small"
                  noMargin
                  icon={<Icon icon={iconBook} size="xs" />}
                /> */}
          <MenuItem
            title="Memory Games"
            url="/memory"
            noMargin
            size="small"
            icon={<Icon icon={iconBrain} size="xs" />}
          />
          <MenuItem
            title="Periodic Table"
            url="/periodic-table"
            noMargin
            size="small"
            icon={<Icon icon={iconPeriodic} size="xs" />}
          />
        </div>
        <div className={style.bottomFixed}>
          {authUser || !!cookies.chemistryshark_auth ? (
            <>
              <MenuItem title="Account" url="/account" size="small" hideIcon />
              <SignOutButton type="mobile" />
            </>
          ) : (
            <>
              <MenuItem title="Pricing" url="/pricing" size="small" hideIcon />
              <MenuItem title="Sign In" url="/login" size="small" hideIcon />
            </>
          )}
        </div>
      </div>
    </Menu>
  );
}
