import classNames from "classnames";
import React from "react";
import style from "./Anchor.module.scss";

interface AnchorProps {
  children?: any;
  href: string;
  className?: any;
  name?: string;
}

export function Anchor(props: AnchorProps) {
  const { children, href, name, className } = props;
  return (
    <a className={classNames(style.anchor, className)} href={href} title={name}>
      {children}
    </a>
  );
}
