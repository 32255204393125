import classNames from "classnames";
import React from "react";
import style from "./Section.module.scss";

interface SectionProps {
  children?: any;
  kind?: "dark" | "light" | "faint" | "transparent" | "emphasis";
  className?: any;
  wide?: boolean;
}

export function Section(props: SectionProps) {
  const { children, kind = "dark", wide, className } = props;

  return (
    <section className={classNames(style.section, style[kind], className)}>
      <div className={classNames(style.contained, wide && style.wide)}>
        {children}
      </div>
    </section>
  );
}
