import LockIcon from "mdi-react/LockIcon";
import React from "react";
import { Anchor } from "../Anchor";
import style from "./ProFeature.module.scss";

interface ProFeatureProps {}

export function ProFeature(props: ProFeatureProps) {
  return (
    <Anchor href="/pricing">
      <span className={style.pro}>
        <LockIcon size={16} style={{ marginRight: 5 }} />
        PRO
      </span>
    </Anchor>
  );
}
