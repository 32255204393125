import classNames from "classnames";
import React from "react";
import { Anchor } from "../Anchor";
import { Heading } from "../Heading";
import { Paragraph } from "../Paragraph";
import style from "./MenuItem.module.scss";

interface MenuItemProps {
  children?: any;
  title: string;
  description?: string;
  icon?: any;
  color?: string;
  size?: "large" | "small" | "noIcon";
  url?: string;
  noMargin?: boolean;
  hideIcon?: boolean;
}

export function MenuItem(props: MenuItemProps) {
  const {
    title,
    description,
    icon,
    color = "transparent",
    size = "large",
    noMargin,
    hideIcon,
    url
  } = props;
  const kind = size === "large" ? "h5" : "h6";

  const MenuTag = url ? Anchor : "div";

  return (
    <MenuTag
      href={url}
      className={classNames(
        style.item,
        noMargin && style.noMargin,
        style[size]
      )}
    >
      {/* // <div className={classNames(style.item, style[size])}> */}
      {!hideIcon && (
        <div>
          <div style={{ backgroundColor: color }} className={style.circle}>
            {icon}
          </div>
        </div>
      )}
      <div className={style.content}>
        <Heading kind={kind}>{title}</Heading>
        {description && <Paragraph small>{description}</Paragraph>}
      </div>
      {/* // </div> */}
    </MenuTag>
  );
}
