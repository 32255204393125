import classNames from "classnames";
import React from "react";
import { Anchor } from "../Anchor";
import { Heading } from "../Heading";
import style from "./Card.module.scss";

interface CardProps {
  children?: any;
  title?: string;

  arrow?: boolean;
  kind?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  icon?: any;
  className?: any;
  paddedTitle?: boolean;
  centerTitle?: boolean;

  padded?: boolean;

  onClick?: any;
  href?: string;
}

export function Card(props: CardProps) {
  const {
    children,
    title,
    arrow,
    paddedTitle,
    centerTitle,
    kind = "h5",
    padded = false,
    onClick,
    href,
    className
  } = props;

  const CardTag = href ? Anchor : "div";
  return (
    <CardTag
      className={classNames(
        style.card,
        arrow && style.withArrow,
        padded && style.padded,
        onClick && style.clickable,
        className
      )}
      onClick={onClick}
      href={href}
    >
      {title && (
        <div
          className={classNames(
            style.title,
            paddedTitle && style.paddedTitle,
            centerTitle && style.center
          )}
        >
          <Heading kind={kind}>{title}</Heading>
        </div>
      )}
      {children}
    </CardTag>
  );
}
