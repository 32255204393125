import React from "react";
import { Heading } from "../Heading";
import { Icon } from "../Icon";
import style from "./InfoSection.module.scss";

interface InfoSectionProps {
  children?: any;

  icon?: any;
  mdiIcon?: any;
  title: string;

  left?: any;
  right?: any;
}

export function InfoSection(props: InfoSectionProps) {
  const { icon, mdiIcon, title, left, right } = props;
  return (
    <section className={style.infoSection}>
      <div className={style.icon}>
        {icon && <Icon icon={icon} />}
        {mdiIcon}
      </div>
      <div className={style.heading}>
        <Heading kind="h2">{title}</Heading>
      </div>
      <div className={style.content}>
        <div className={style.section}>{left}</div>
        <div className={style.section}>{right}</div>
      </div>
    </section>
  );
}
