import classNames from "classnames";
import React from "react";
import style from "./Paragraph.module.scss";

interface ParagraphProps {
  children?: any;
  kind?: "div" | "p";
  faint?: boolean;
  small?: boolean; // reduce font size and line height
  className?: string;
}

export function Paragraph(props: ParagraphProps) {
  const { kind = "p", faint, small, children, className } = props;
  const Label = kind;
  return (
    <Label
      className={classNames(
        style.text,
        faint && style.faint,
        small && style.small,
        className
      )}
    >
      {children}
    </Label>
  );
}
