import React from "react";
import style from "./Hero.module.scss";
import classNames from "classnames";

interface HeroProps {
  children?: any;
  kind?: "primary" | "light" | "dark";
}

export function Hero(props: HeroProps) {
  const { kind = "light" } = props;
  return (
    <div className={style.heroWrapper}>
      <div className={classNames(style.hero, style[kind])}>
        <svg viewBox="0 0 100 100" preserveAspectRatio="none">
          <polygon points="0,100 100,0 100,100" />
        </svg>
      </div>
    </div>
  );
}
