import { createBrowserHistory } from "history";
import ReactGA from "react-ga";

export function useTracking() {
  const history = createBrowserHistory();

  const setPageChange = () => {
    if (history && history.location) {
      ReactGA.set({ page: history.location.pathname }); // Update the user's current page
      ReactGA.pageview(history.location.pathname); // Record a pageview for the given page
    }
  };

  const createEvent = ({ category, action }) => {
    ReactGA.event({
      category,
      action
    });
  };

  if (process.env.REACT_APP_MODE === "development") {
    return {
      setPageChange: null,
      createEvent: null
    };
  }

  return {
    setPageChange,
    createEvent
  };
}
