import React, { useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { asyncComponent } from "./components/asyncComponent";
import { PageNotFound } from "./components/PageNotFound";
import { withAuthentication } from "./components/Session";
import Home from "./routes/home";
import { useTracking } from "./utils/useTracking";

function App(props) {
  const { setPageChange } = useTracking();

  useEffect(() => {
    if (setPageChange) {
      setPageChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const AsyncCalculator = asyncComponent(() => import("./routes/calculator"));
  const AsyncMemory = asyncComponent(() => import("./routes/memory"));
  const AsyncSheets = asyncComponent(() => import("./routes/sheets"));
  const AsyncPeriodic = asyncComponent(() => import("./routes/periodicTable"));
  const AsyncPricing = asyncComponent(() => import("./routes/pricing"));
  const AsyncTerms = asyncComponent(() => import("./routes/terms"));
  const AsyncPrivacy = asyncComponent(() => import("./routes/privacy"));
  const AsyncContact = asyncComponent(() => import("./routes/contact"));
  const AsyncLogin = asyncComponent(() => import("./routes/login"));
  const AsyncRegister = asyncComponent(() => import("./routes/register"));
  const AsyncAccount = asyncComponent(() => import("./routes/account"));
  const AsyncReset = asyncComponent(() => import("./routes/passwordForgot"));

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/calculator" component={AsyncCalculator} />
        <Route path="/memory" component={AsyncMemory} />
        <Route path="/sheets" component={AsyncSheets} />

        <Route exact path="/periodic-table" component={AsyncPeriodic} />
        <Route exact path="/pricing" component={AsyncPricing} />
        <Route exact path="/terms" component={AsyncTerms} />
        <Route exact path="/privacy" component={AsyncPrivacy} />
        <Route exact path="/contact" component={AsyncContact} />
        <Route exact path="/login" component={AsyncLogin} />
        <Route exact path="/register" component={AsyncRegister} />
        <Route exact path="/account" component={AsyncAccount} />
        <Route exact path="/reset-password" component={AsyncReset} />

        <Redirect exact from="/lab/titration" to="/calculator/titration" />
        <Route path="/" component={PageNotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default withAuthentication(App);
