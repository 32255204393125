import React from "react";
import { Anchor } from "../Anchor";
import { Heading } from "../Heading";
import { Page } from "../Page";
import { Paragraph } from "../Paragraph";
import style from "./PageNotFound.module.scss";

export function PageNotFound() {
  //   const Label = kind;
  return (
    <Page kind="dark" title="Page not found">
      <div className={style.content}>
        <div className={style.main}>
          <div className={style.code}>404</div>
          <div className={style.sorry}>
            <Heading kind="h1">SORRY!</Heading>
            <Paragraph faint>
              The page you're looking for was not found.
            </Paragraph>
          </div>
        </div>

        <div className={style.return}>
          <div className={style.option}>
            <Heading kind="h5">
              <Anchor href="/">Back to home page</Anchor>
            </Heading>
            <Paragraph faint>
              if you'd like to check out the rest of the site.
            </Paragraph>
          </div>

          <div className={style.option}>
            <Heading kind="h5">
              <Anchor href="/contact">Contact us</Anchor>
            </Heading>
            <Paragraph faint>
              if you can't find what you're looking for.
            </Paragraph>
          </div>
        </div>
      </div>
    </Page>
  );
}
