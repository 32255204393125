import React from "react";
import { useLocation } from "react-router-dom";
import { useTracking } from "../../utils/useTracking";
import { Button } from "../Button";
import { Heading } from "../Heading";
import { Section } from "../Section";
import style from "./CTA.module.scss";

interface CTAProps {
  children?: any;
  kind?: "dark" | "light" | "emphasis" | "transparent";
  description: string;
  href: string;
  button: string;

  icon?: any;
  image?: any;
}

export function CTA(props: CTAProps) {
  const { kind = "dark", description, href, button, icon, image } = props;
  const { createEvent } = useTracking();
  const location = useLocation();

  return (
    <Section kind={kind}>
      <div className={style.cta}>
        {(icon || image) && (
          <div className={style.icon}>
            {icon ? (
              <img src={icon} alt="Become a Chemistryshark Pro member" />
            ) : (
              image
            )}
          </div>
        )}
        <div className={style.heading}>
          <Heading kind="h2" weight="normal" className={style.ctaHeading}>
            {description}
          </Heading>
        </div>
        <Button
          href={href}
          onClick={() => {
            if (createEvent) {
              createEvent({
                category: "CTA",
                action: `Clicked - ${location && location.pathname}`
              });
            }
          }}
        >
          {button}
        </Button>
      </div>
    </Section>
  );
}
