import React from "react";
import { Icon } from "../../components/Icon";
import { MenuDropdown } from "../MenuDropdown";
import { MenuItem } from "../MenuItem";
import iconAtom from "./assets/atoms.svg";
import iconBlackboard from "./assets/blackboard.svg";
import iconBond from "./assets/bond.svg";
import iconBurner from "./assets/burner.svg";
import iconFlasks from "./assets/flasks.svg";
import iconKinetics from "./assets/gravity.svg";
import iconMolecule from "./assets/molecules.svg";
import iconNotes from "./assets/notes.svg";
import iconOxygen from "./assets/oxygen.svg";
import style from "./SheetsDropdown.module.scss";

export const sheets = [
  {
    title: "Acid and Base Formulas",
    icon: iconFlasks,
    slug: "acid-base",
    description:
      "Explore equations relating to pH, pOH, dissociation and equilibrium constants."
  },
  {
    title: "General Knowledge",
    icon: iconBlackboard,
    slug: "general",
    description:
      "Covers introductory material such as solubility rules, SI prefixes and measurements, and significant figure rules."
  },
  {
    title: "Common Ions",
    icon: iconAtom,
    slug: "ions",
    description:
      "Large list of common polyatomic ions, formulas, and their charges."
  },
  {
    title: "Organic Functional Groups",
    icon: iconBond,
    slug: "functional-groups",
    description:
      "From alkanes and alcohols to esters and carboxylic acids, associate the organic functional group with its structure."
  },
  {
    title: "Gas Laws Formulas",
    icon: iconOxygen,
    slug: "gases",
    description:
      "Commonly used gas-related equations, ranging from Boyle's Law and Charles' Law to Van der Waal's Equation and the Combined Gas Law."
  },
  {
    title: "Kinetics Formulas",
    icon: iconKinetics,
    slug: "kinetics",
    description:
      "Equations for integrated rate laws, half-life, and rate for zero-order through second-order kinetics, as well as forms of the Arrhenius equation."
  },
  {
    title: "Molecular Geometry Chart",
    icon: iconMolecule,
    slug: "molecular-geometry",
    description:
      "Trigonal planar or trigonal pyramidal? Check out the table of common electron domain and molecular geometries, with bonding domains, bond angles, and formulas."
  },
  {
    title: "List of Acids & Bases",
    icon: iconNotes,
    slug: "acid-base-list",
    description:
      "Cheat sheet that lists all strong acids and bases, along with commonly used weak acids and bases."
  },
  {
    title: "Thermodynamics Formulas",
    icon: iconBurner,
    slug: "thermodynamics",
    description:
      "Formula sheet that includes equations for enthalpy, entropy, free energy, heat capacity, and the laws of thermodynamics."
  }
];

export function SheetsDropdown(props: any) {
  return (
    <MenuDropdown
      trigger="Cheat Sheets"
      slug="sheets"
      menuStyle={style.menu}
      contentStyle={style.content}
      {...props}
    >
      <div className={style.main}>
        {sheets.map(sheet => (
          <MenuItem
            title={sheet.title}
            key={sheet.slug}
            size="small"
            url={`/sheets/${sheet.slug}`}
            icon={<Icon icon={sheet.icon} size="small" />}
          />
        ))}
      </div>
    </MenuDropdown>
  );
}
