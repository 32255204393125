import React from "react";
import { Icon } from "../../components/Icon";
import { MenuDropdown } from "../MenuDropdown";
import { MenuItem } from "../MenuItem";
// import iconBook from "./assets/book.svg";
import iconBrain from "./assets/brain.svg";
import iconPeriodic from "./assets/periodic-table.svg";
import style from "./PracticeDropdown.module.scss";

export function PracticeDropdown(props: any) {
  return (
    <MenuDropdown
      trigger="Practice"
      slug="practice"
      menuStyle={style.menu}
      contentStyle={style.content}
      {...props}
    >
      <div className={style.main}>
        <MenuItem
          title="Periodic Table of Elements"
          url="/periodic-table"
          description="Dynamic periodic table with names, charges, atomic mass as well as more specific information such as electronic configuration, element radius, and much more."
          icon={<Icon icon={iconPeriodic} />}
        />
        {/* <MenuItem
          title="Titration Problem Examples"
          url="/calculator/ph/examples"
          description="List of various acid-base pH and titration example problems for checking results and guiding solutions."
          icon={<Icon icon={iconBook} />}
        /> */}
      </div>

      <div className={style.sub}>
        <MenuItem
          title="Memory Game"
          description="Study chemistry vocabulary in a game of flip-card concentration! Improve your score and master the subject."
          icon={<Icon icon={iconBrain} />}
        />
        <MenuItem
          title="Acids and Bases"
          url="/memory/acidbase"
          size="noIcon"
        />
        <MenuItem
          title="The Periodic Table"
          url="/memory/periodic"
          size="noIcon"
        />
        <MenuItem
          title="Thermodynamics"
          url="/memory/thermodynamics"
          size="noIcon"
        />
      </div>
    </MenuDropdown>
  );
}
