import classNames from "classnames";
import React from "react";
import logo_with_text from "../../assets/logo_text_white.png";
import logo from "../../assets/logo_white.png";
import { Anchor } from "../Anchor";
import style from "./Logo.module.scss";

interface LogoProps {
  children?: any;

  withText?: boolean;
  center?: boolean;
  link?: boolean;
}

export function Logo(props: LogoProps) {
  const { withText = true, center, link } = props;
  const LogoTag = link ? Anchor : "div";
  return (
    <LogoTag href="/">
      <div
        className={classNames(
          style.logo,
          center && style.center,
          withText && style.withText
        )}
      >
        <img src={withText ? logo_with_text : logo} alt="Chemistryshark" />
      </div>
    </LogoTag>
  );
}
