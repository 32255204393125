import classNames from "classnames";
import { format } from "date-fns";
import FacebookIcon from "mdi-react/FacebookIcon";
import LinkedinIcon from "mdi-react/LinkedinIcon";
import TwitterIcon from "mdi-react/TwitterIcon";
import React from "react";
import { Anchor } from "../Anchor";
import { Heading } from "../Heading";
import style from "./Footer.module.scss";

const footers = [
  {
    name: "General",
    base: "",
    links: [
      {
        name: "Contact",
        url: "/contact"
      },
      {
        name: "Pricing",
        url: "/pricing"
      },
      {
        name: "Account",
        url: "/account"
      },
      {
        name: "Terms & Conditions",
        url: "/terms"
      },
      {
        name: "Privacy Policy",
        url: "/privacy"
      }
    ]
  },
  {
    name: "Calculators",
    base: "/calculator",
    links: [
      {
        name: "pH Calculator",
        slug: "ph"
      },
      {
        name: "Titration Lab",
        slug: null,
        url: "/calculator/titration-lab"
      },
      {
        name: "Voltaic Cell Calculator",
        slug: "voltaic"
      },
      {
        name: "Molar Mass Calculator",
        slug: "mass"
      },
      {
        name: "Quadratic Formula Calculator",
        slug: "quadratic"
      },
      {
        name: "Temperature Converter",
        slug: "temperature"
      }
    ]
  },
  {
    name: "Cheat Sheets",
    base: "/sheets",
    links: [
      {
        name: "Acid and Base Formulas",
        slug: "acid-base"
      },
      {
        name: "General Knowledge",
        slug: "general"
      },
      {
        name: "Common Ions",
        slug: "ions"
      },
      {
        name: "Organic Functional Groups",
        slug: "functional-groups"
      },
      {
        name: "Gas Laws Formulas",
        slug: "gases"
      },
      {
        name: "Kinetics Formulas",
        slug: "kinetics"
      },
      {
        name: "Molecular Geometry Chart",
        slug: "molecular-geometry"
      },
      {
        name: "List of Acids & Bases",
        slug: "acid-base-list"
      },
      {
        name: "Thermodynamics Formulas",
        slug: "thermodynamics"
      }
    ]
  },
  {
    name: "Practice",
    base: "",
    links: [
      {
        name: "Periodic Table of Elements",
        url: "/periodic-table"
      },
      // {
      //   name: "Titration Problem Examples",
      //   url: "/calculator/ph/examples"
      // },
      {
        name: "Acid & Base Memory",
        url: "/memory/acidbase"
      },
      {
        name: "Periodic Table Memory",
        url: "/memory/periodic"
      },
      {
        name: "Thermodynamics Memory",
        url: "/memory/thermodynamics"
      }
    ]
  }
];

interface FooterProps {
  children?: any;
  kind?: "dark" | "light" | "primary";
}

export function Footer(props: FooterProps) {
  const { kind } = props;

  const year = format(new Date(), "yyyy");

  return (
    <footer className={style.footer}>
      <div
        className={classNames(
          style.content,
          kind === "dark" && style.contentDark
        )}
      >
        {footers.map(section => {
          const links = section.links as any;
          return (
            <div
              key={section.name}
              className={classNames(style.section, style[kind])}
            >
              <Heading kind="h6">{section.name}</Heading>
              <ul>
                {links.map(
                  (link: { url: any; slug: any; name: React.ReactNode }) => (
                    <li key={link.url || link.name}>
                      <Anchor href={link.url || `${section.base}/${link.slug}`}>
                        {link.name}
                      </Anchor>
                    </li>
                  )
                )}
              </ul>
            </div>
          );
        })}
        <div className={style.extra}>
          <div className={style.social}>
            <Anchor
              href="https://www.facebook.com/Chemistryshark/"
              className={style.socialIcon}
              name="Facebook"
            >
              <FacebookIcon />
            </Anchor>
            <Anchor
              href="https://twitter.com/chemistryshark_"
              className={style.socialIcon}
              name="TWitter"
            >
              <TwitterIcon />
            </Anchor>
            <Anchor
              href="https://www.linkedin.com/company/chemistryshark"
              className={style.socialIcon}
              name="LinkedIn"
            >
              <LinkedinIcon />
            </Anchor>
          </div>
          <div>
            <small>&copy; Copyright {year}, Chemistryshark</small>
          </div>
          <div className={style.attributions}>
            <small>
              <Anchor href="https://www.linkedin.com/in/grantrygh">
                Hand-crafted and made with{" "}
                <span className={style.heart}>&#x2764;</span>
              </Anchor>
            </small>
            <small>
              Icons made by{" "}
              <Anchor href="https://www.flaticon.com/authors/freepik">
                Freepik
              </Anchor>{" "}
              from <Anchor href="https://www.flaticon.com">flaticon</Anchor>
            </small>
          </div>
        </div>
      </div>
    </footer>
  );
}
