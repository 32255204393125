import classNames from "classnames";
import React from "react";
import logo from "../../assets/logo_white.png";
import iconVoltaic from "../CalculatorDropdown/assets/battery.svg";
import iconMass from "../CalculatorDropdown/assets/molecule.svg";
import iconPh from "../CalculatorDropdown/assets/ph-meter.svg";
import iconBook from "../PracticeDropdown/assets/book.svg";
import iconBlackboard from "../SheetsDropdown/assets/blackboard.svg";
import iconBond from "../SheetsDropdown/assets/bond.svg";
import iconFlasks from "../SheetsDropdown/assets/flasks.svg";
import iconMolecules from "../SheetsDropdown/assets/molecules.svg";
import style from "./HeroAtom.module.scss";

export function HeroAtom(props: any) {
  const CircleIcon = props => (
    <div
      className={classNames(
        style.icon,
        style[`icon_${props.position}`],
        props.className
      )}
    >
      <img src={props.icon} alt={props.alt || ""} />
    </div>
  );

  return (
    <div className={style.atom}>
      <div className={classNames(style.ring, style.ringOuter)}>
        <CircleIcon icon={iconMolecules} position="left" alt="Molecules" />
        <CircleIcon icon={iconBond} position="right" alt="Bond" />
        <CircleIcon icon={iconFlasks} position="bottom" alt="Flasks" />
        <CircleIcon icon={iconBook} position="top" alt="Book" />

        <div className={classNames(style.ring, style.ringMiddle)}>
          <CircleIcon icon={iconVoltaic} position="top" alt="Voltaic cell" />
          <CircleIcon
            icon={iconBlackboard}
            position="bottom"
            alt="Blackboard"
          />
          <div className={classNames(style.ring, style.ringInner)}>
            <CircleIcon icon={iconMass} position="left" alt="Atom mass" />
            <CircleIcon icon={iconPh} position="right" alt="PH gauge" />
            <CircleIcon
              icon={logo}
              className={style.logo}
              alt="Chemistryshark logo"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
